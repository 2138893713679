<template>
  <div>
    <Modal
      v-model="downloadModal"
      width="400"
    >
      <p
        slot="header"
        class="text-center"
      >资源导出</p>
      <div class="m-b-10">
        <Select
          v-model="downloadAssetIds"
          :multiple="true"
          size="small"
          placeholder="选择资产（可多选）"
        >
          <Option
            :value="item.id"
            v-for="item in assetArray"
            :key="'aid'+item.id"
          >{{item.name}}</Option>
        </Select>
      </div>
      <div slot="footer">
        <Button
          type="text"
          class="m-r-5"
          @click="downloadModal=false"
        >取消</Button>
        <Button
          type="primary"
          :disabled="downloadAssetIds.length===0"
          @click="confirmDownload"
        >确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
/** new api */
import { startExportResource } from '@/api/product/resource'
export default {
  props: {
    assetArray: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      downloadModal: false,
      sign: '',
      downloadAssetIds: []
    }
  },
  methods: {
    handleDownload () {
      this.downloadAssetIds = []
      this.deviceBigModel = null
      this.downloadModal = true
    },
    confirmDownload () {
      this.sign = ''
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定选择无误并导出？',
        onOk: () => {
          this.downloadModal = false
          this.$Spin.show({
            render: (h) => {
              return h('div', [
                h('Icon', {
                  class: 'spin-icon-load',
                  props: {
                    type: 'ios-loading',
                    size: 24
                  }
                }),
                h('div', '数据生成中...')
              ])
            }
          })
          this.fileDownload()
        }
      })
    },
    fileDownload () {
      startExportResource({ assetIds: JSON.stringify(this.downloadAssetIds), sign: this.sign }).then(res => {
        if (res && !res.errcode) {
          this.sign = res.sign
          if (res.status === 2) {
            this.$Spin.hide()

            const filename = '资源列表.xlsx'

            downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-product/v1/resource/downloadExcel', { sign: this.sign }, filename)
          } else {
            setTimeout(() => {
              this.fileDownload()
            }, 3000)
          }
        } else {
          this.$Spin.hide()
        }
      }).catch(() => {
        this.$Spin.hide()
        this.$Notice.error({ desc: '发生一个异常' })
      })
    }
  }
}
</script>
